import { Link } from "react-router-dom"
import supabase from "../config/supabaseClient"
const Chart = ({ chart, onDelete }) => {
    const deleteIrregs = async () => {

        const { data, error } = await supabase
            .from('irregulars')
            .delete()
            .eq('chart', chart.id)
            .select()
        if (error) {
            console.log(error)
        }
        if (data) {
            console.log(data)
            //onDelete(chart.id)
        }
        
    }
    
    const handleDelete = async () => {

        const { data, error } = await supabase
            .from('charts')
            .delete()
            .eq('id', chart.id)
            .select()
            deleteIrregs()
        if (error) {
            console.log(error)
        }
        if (data) {
            console.log(data)
            onDelete(chart.id)
        }
        
    }
    
    return (
        
        <div className="chart-card">
            <h3 className="title">{chart.title}</h3>
            <table>
            <thead>
                <td>{chart.yo}</td>
                <td>{chart.nosotros}</td>
            </thead>
            <thead>
                <td>{chart.tu}</td>
                <td>{chart.vosotros}</td>
            </thead>
            <thead>
                <td>{chart.el}</td>
                <td>{chart.ellos}</td>
            </thead>
            </table>
            
            <div className="buttons">
                <Link to={'/' + chart.id + '/update'}>
                    <i className="material-icons">edit</i>
                </Link>
                
                <i className="material-icons" onClick={handleDelete}>delete</i>

                <Link to={'/' + chart.id + '/study'}>
                    <i className="material-icons">play_arrow</i>
                </Link>
            </div>
        </div>

    )
}

export default Chart