import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import { useParams} from 'react-router-dom'
import {useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom"
//components
import ChartCard from "../components/chart"
import IrregularCard from "../components/irregular"
import {Helmet} from "react-helmet";

const Create = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [fetchError, setFetchError] = useState(null)
  const [charts, setCharts] = useState(null)
  const [irregulars, setIrregulars] = useState(null)
  const [verb, setVerb] = useState('')
  const [hasFetched, setHasFetched] = useState(false)
  const [hasFetchedVerb, setHasFetchedVerb] = useState(false)

  async function checkverb(){
    const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('verb', verb)
        .order('id', { ascending: true})
        .limit(1)
        .single()

      if (error) {
        console.log(error)
        navigate('/' + id + '/fill')

      }
      if (data) {
        navigate('/' + data.id + '/fill')
        
      }
  }

  async function next(){
    const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('chart', id)
        .single()

      if (error) {
        console.log(error);
      }
      if (data) {
        navigate('/' + data.id + '/fill/')
        
      }
  }
  
  const handleDelete = (id) => {
    setCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
      
    })
  }

  useEffect(() => {
    
    const fetchVerb = async () => {
      if (!hasFetchedVerb) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('id', id)
        .single()
      if (error) {
          setFetchError('could not fetch the verb')
          console.log(error)
          fetchCharts()
        }
  
      if (data) {
          setVerb(data.verb)
          
          setHasFetchedVerb(true)

          //if === null fetchcharts
          if (data.verb === null){
            fetchCharts()

          }
          else{
          fetchChartsByVerb(data.verb)
          setHasFetched(true)
          }
        }
      }
    }
  
    
    const fetchCharts = async () => {
      if (!hasFetched) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('id', id)
        

      if (error) {
        setFetchError('could not fetch the charts')
        setCharts(null)
        console.log(error)
      }

      if (data) {
        setCharts(data)
        fetchIrregs()
        setHasFetched(true)
        setFetchError(null)
      }
    }
  }
    const fetchChartsByVerb = async (verb) => {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('verb', verb)
        

      if (error) {
        setFetchError('could not fetch the charts')
        setCharts(null)
        console.log(error)
      }

      if (data) {
        setCharts(data)
        setFetchError(null)
      }
    }
    const fetchIrregs = async () => {
        const { data, error } = await supabase
          .from('irregulars')
          .select()
          .eq('chart', id)
          
  
        if (error) {
          setFetchError('could not fetch the irregulars')
          setIrregulars(null)
          console.log(error)
        }
  
        if (data) {
          setIrregulars(data)
          setFetchError(null)
        }
      }
    fetchVerb()
    //fetchIrregs()
    //fetchCharts()
    
  })
  return (
    <div className="page home">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Study</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
      <div className="spacer">
        <div className="buttons">
        
            <button className="study-mode" onClick={checkverb}><h1>Fill Mode</h1></button>
        </div>
        </div> 
        <div className="fill-mode">
       
        
        
      {fetchError && (<p>{fetchError}</p>)}
      {charts && (
        <div className="charts">
          <div className="chart-grid">
            {charts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
            {irregulars && irregulars.map(irregular => (
                
              <IrregularCard key={irregular.id} irregular={irregular} onDelete={handleDelete}/>
            ))}
          </div>
        </div>
      )}
    </div>
    </div>
  ) 
}
  
  export default Create