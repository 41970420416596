import { useState } from "react"
import { useNavigate } from "react-router-dom"
import supabase from "../config/supabaseClient"
import {Helmet} from "react-helmet";
//components
import SpanishButtons from "../components/SpanishCharacterButtons"


const Create = () => {
  const navigate = useNavigate()

  const [title, setTitle] = useState('')
  const [verb, setVerb] = useState('')
  const [tense, setTense] = useState('')
  const [yo, setyo] = useState('')
  const [tu, settu] = useState('')
  const [el, setel] = useState('')
  const [nosotros, setnosotros] = useState('')
  const [vosotros, setvosotros] = useState('')
  const [ellos, setellos] = useState('')

  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!title) {
      setFormError('Please add a title')
      return
    }

    const { data, error } = await supabase
      .from('charts')
      .insert([{yo, tu, el, nosotros, vosotros, ellos, title, tense, verb }])
      .select()

    if (error) {
      setFormError('Please fill in all the fields correctly.')
      console.log(error)
    }
    if (data) {
      setFormError(null)
      navigate("/")
      console.log(data)
    }

  }
    

  return (
    <div className="form-grid">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Create a chart</title>
                <link rel="canonical" href="http://conuquick.com" />
      </Helmet>
      {/* Normal Input*/}
      <div className="chart-input">
      <form onSubmit={handleSubmit}>
        <h2>Add Verb Chart</h2>
        <label htmlFor="title">Title:</label>
        
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label htmlFor="title">Tense:</label>
        <input
          type="text"
          id="tense"
          value={tense}
          onChange={(e) => setTense(e.target.value)}
        />
        <label htmlFor="title">Verb:</label>
        <input
          type="text"
          id="verb"
          value={verb}
          onChange={(e) => setVerb(e.target.value)}
        />
        <table>
          <thead>
            <td>
        <label htmlFor="yo">Yo:</label>
        <textarea
          id="yo"
          value={yo}
          onChange={(e) => setyo(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="nosotros">Nosotros:</label>
        <textarea
          id="nosotros"
          value={nosotros}
          onChange={(e) => setnosotros(e.target.value)}
        />
        </td>
        </thead>
        <thead>
          <td>
        <label htmlFor="tu">Tu:</label>
        <textarea
          id="tu"
          value={tu}
          onChange={(e) => settu(e.target.value)}
        />
        </td>

        <td>
        <label htmlFor="vosotros">Vosotros</label>
        <textarea
          id="vosotros"
          value={vosotros}
          onChange={(e) => setvosotros(e.target.value)}
        />
        </td>
        </thead>

        <thead>
          <td>
        <label htmlFor="el">El/Ella/Usted:</label>
        <textarea
          id="el"
          value={el}
          onChange={(e) => setel(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="ellos">Ellos/Ellas/Ustedes:</label>
        <textarea
          id="ellos"
          value={ellos}
          onChange={(e) => setellos(e.target.value)}
        />
        </td>
        </thead>
        </table>
        <SpanishButtons />
        <button className="submit">Add Chart</button>

        {formError && <p className="error">{formError}</p>}
      </form>
      </div>
      </div>

  )
}

export default Create