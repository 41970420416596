import { clear } from "@testing-library/user-event/dist/clear"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import supabase from "../config/supabaseClient"
import {Helmet} from "react-helmet";

const FillChart = () => {
  //const { id } = chart.id;
  const { id } = useParams()
  var numCorrect = 0;
  const navigate = useNavigate()
  const [chart, setChart] = useState('')
  const [verb, setVerb] = useState('')
  const [yo, setyo] = useState('')
  const [tu, settu] = useState('')
  const [el, setel] = useState('')
  const [nosotros, setnosotros] = useState('')
  const [vosotros, setvosotros] = useState('')
  const [ellos, setellos] = useState('')
  const [syo, setsyo] = useState('')
  const [stu, setstu] = useState('')
  const [sel, setsel] = useState('')
  const [snosotros, setsnosotros] = useState('')
  const [svosotros, setsvosotros] = useState('')
  const [sellos, setsellos] = useState('')
  const [formError, setFormError] = useState(null)


  async function next(){
    const { data, error } = await supabase
        .from('irregulars')
        .select()
        .eq('chart', chart)
        .gt('id', id)
        .order('id', { ascending: true})
        
        .limit(1)
        .single()

      if (error) {
        navigate('/' + chart + '/fill/complete')
        console.log(error);
      }
      if (data) {
        navigate('/' + data.id + '/fill/irregular')
        
      }
  }

  function clear(){
    numCorrect = 0;
    document.getElementById('yo-test').innerHTML = '';
    document.getElementById('tu-test').innerHTML = '';
    document.getElementById('el-test').innerHTML = '';
    document.getElementById('nosotros-test').innerHTML = '';
    document.getElementById('vosotros-test').innerHTML = '';
    document.getElementById('ellos-test').innerHTML = '';
    document.getElementById('yo').value = '';
    document.getElementById('tu').value = '';
    document.getElementById('el').value = '';
    document.getElementById('nosotros').value = '';
    document.getElementById('vosotros').value = '';
    document.getElementById('ellos').value = '';
    document.getElementById('yo-correct').innerHTML = '';
    document.getElementById('tu-correct').innerHTML = '';
    document.getElementById('el-correct').innerHTML = '';
    document.getElementById('nosotros-correct').innerHTML = '';
    document.getElementById('vosotros-correct').innerHTML = '';
    document.getElementById('ellos-correct').innerHTML = '';
  }
  const showAnswers = () => {
    document.getElementById('yo-correct').innerHTML = syo
    document.getElementById('tu-correct').innerHTML = stu
    document.getElementById('el-correct').innerHTML = sel
    document.getElementById('nosotros-correct').innerHTML = snosotros
    document.getElementById('vosotros-correct').innerHTML = svosotros
    document.getElementById('ellos-correct').innerHTML = sellos
  };
  const correct = (divId) => {
    const div = document.getElementById(divId);
    div.innerHTML = '✅';
    numCorrect++;
  };
  const incorrect = (divId) => {
    const div = document.getElementById(divId);
    div.innerHTML = '❌';
  };

  const handleSubmit = async (e) => {
    numCorrect = 0;
    e.preventDefault()
    const { data, error } = await supabase
    .from('charts')
    //.update({ yo, tu, el, nosotros, vosotros, ellos, title })
    //.eq('id', id)
    .select()
        

    if (error) {
      setFormError('Please fill in all the fields correctly.')
    }
    if (data) {
      console.log(yo)
      console.log(syo)
      console.log(yo === syo);
      setFormError(null)
      //check answers
      if (yo.toLowerCase() === syo.toLowerCase()) {
        correct('yo-test');
      }
      else{
        incorrect('yo-test');
      }
      if (tu.toLowerCase() === stu.toLowerCase()) {
        correct('tu-test')
      }
      else{
        incorrect('tu-test')
      }
      if (el.toLowerCase() === sel.toLowerCase()) {
        correct('el-test');
      }
      else{
        incorrect('el-test');
      }
      if (nosotros.toLowerCase() === snosotros.toLowerCase()) {
        correct('nosotros-test')
      }
      else{
        incorrect('nosotros-test')
      }
      if (vosotros.toLowerCase() === svosotros.toLowerCase()) {
        correct('vosotros-test');
      }
      else{
        incorrect('vosotros-test');
      }
      if (ellos.toLowerCase() === sellos.toLowerCase()) {
        correct('ellos-test')
      }
      else{
        incorrect('ellos-test')
      }

      //show score + options
      document.getElementById('score').innerHTML=('Score: ' + numCorrect + '/6')
      
    }
  }


  useEffect(() => {
    clear();
    const fetchChart = async () => {
      const { data, error } = await supabase
        .from('irregulars')
        .select()
        .eq('id', id)
        .single()

      if (error) {
        console.log(error);
      }
      if (data) {
        setChart(data.chart)
        setVerb(data.iverb)
        setsyo(data.iyo)
        setstu(data.itu)
        setsel(data.iel)
        setsnosotros(data.inosotros)
        setsvosotros(data.ivosotros)
        setsellos(data.iellos)
        
      }
    }
      fetchChart()
  }, [id, navigate])

  return (
<div className="form-grid">
<Helmet>
                <meta charSet="utf-8" />
                <title>Fill Mode</title>
                <link rel="canonical" href="http://conuquick.com" />
      </Helmet>
      {/* Normal Input*/}
      <div className="chart-input">
      <form onSubmit={handleSubmit}>
        
        <h2>{verb}</h2>
          
        <table>
          <thead>
            <td>
            
        <label htmlFor="yo">Yo:</label>
        <textarea
          id="yo"
          onChange={(e) => setyo(e.target.value)}
        />
        <div id="yo-test"><p></p></div>
        <div id="yo-correct"><p></p></div>
        </td>
        <td>
        <label htmlFor="nosotros">Nosotros:</label>
        <textarea
          id="nosotros"
          
          onChange={(e) => setnosotros(e.target.value)}
        />
        <div id="nosotros-test"><p></p></div>
        <div id="nosotros-correct"><p></p></div>
        </td>
        </thead>
        <thead>
          <td>
        <label htmlFor="tu">Tu:</label>
        <textarea
          id="tu"
          onChange={(e) => settu(e.target.value)}
        />
        <div id="tu-test"><p></p></div>
        <div id="tu-correct"><p></p></div>
        </td>

        <td>
        <label htmlFor="vosotros">Vosotros</label>
        <textarea
          id="vosotros"
          onChange={(e) => setvosotros(e.target.value)}
        />
        <div id="vosotros-test"><p></p></div>
        <div id="vosotros-correct"><p></p></div>
        </td>
        </thead>

        <thead>
          <td>
        <label htmlFor="el">El/Ella/Usted:</label>
        <textarea
          id="el"
          onChange={(e) => setel(e.target.value)}
        />
        <div id="el-test"><p></p></div>
        <div id="el-correct"><p></p></div>
        </td>
        <td>
        <label htmlFor="ellos">Ellos/Ellas/Ustedes:</label>
        <textarea
          id="ellos"
          onChange={(e) => setellos(e.target.value)}
        />
        <div id="ellos-test"><p></p></div>
        <div id="ellos-correct"><p></p></div>
        </td>
        </thead>
        </table>


        <button className="submit">Submit</button>
        
      </form>
      <div id="report">
        <p id="score"></p>
        <button onClick={clear} className='button'>Clear</button>
        <button onClick={next} className='button'>Next</button>
        <button onClick={showAnswers} className='button'>Show Answers</button>
        {formError && <p className="error">{formError}</p>}
      </div>     
      </div>
          
           
            
    </div>
     
    
  )}

export default FillChart