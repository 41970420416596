import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import supabase from "../config/supabaseClient"
import { Link } from "react-router-dom"
import Irregular from "../components/irregular"
import IrregCard from "../components/irregcard"
import {Helmet} from "react-helmet";
import SpanishButtons from "../components/SpanishCharacterButtons"

const Update = () => {
  const { id } = useParams()
  const chart = id;
  const [irregulars, setIrregulars] = useState(null)
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [yo, setyo] = useState('')
  const [tu, settu] = useState('')
  const [el, setel] = useState('')
  const [nosotros, setnosotros] = useState('')
  const [vosotros, setvosotros] = useState('')
  const [ellos, setellos] = useState('')
  //Irregulars
  const [iverb, isetverb] = useState('')
  const [iyo, isetyo] = useState('')
  const [itu, isettu] = useState('')
  const [iel, isetel] = useState('')
  const [inosotros, isetnosotros] = useState('')
  const [ivosotros, isetvosotros] = useState('')
  const [iellos, isetellos] = useState('')
  const [formError, setFormError] = useState(null)

  function refreshPage() {
    window.location.reload(false);
  }
  const handleDelete = (id) => {
   console.log("delete")
   setIrregulars(prevCharts => {
    return prevCharts.filter(sm => sm.id !== id)
  })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!title) {
      setFormError('Please add a title')
      return
    }

    const { data, error } = await supabase
      .from('charts')
      .update({ yo, tu, el, nosotros, vosotros, ellos, title })
      .eq('id', id)
      .select()

    if (error) {
      setFormError('Please fill in all the fields correctly.')
    }
    if (data) {
      setFormError(null)
      navigate('/')
    }
  }



  useEffect(() => {
    
    const fetchChart = async () => {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('id', id)
        .single()

      if (error) {
        navigate('/', { replace: true })
      }
      if (data) {
        setTitle(data.title)
        setyo(data.yo)
        settu(data.tu)
        setel(data.el)
        setnosotros(data.nosotros)
        setvosotros(data.vosotros)
        setellos(data.ellos)
        isetyo(data.yo)
        isettu(data.tu)
        isetel(data.el)
        isetnosotros(data.nosotros)
        isetvosotros(data.vosotros)
        isetellos(data.ellos)
      
      }
    }
      fetchChart()
  }, [id, navigate])

  return (
  <div className="form-grid">
      
      <Helmet>
                <meta charSet="utf-8" />
                <title>Update Set</title>
                <link rel="canonical" href="http://conuquick.com" />
      </Helmet>

      {/* Normal Input*/}
      <div className="chart-input">
      <form onSubmit={handleSubmit}>
        <h2>Edit Verb Chart</h2>
        <label htmlFor="title">Title:</label>
        
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <table>
          <thead>
            <td>
        <label htmlFor="yo">Yo:</label>
        <textarea
          id="yo"
          value={yo}
          onChange={(e) => setyo(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="nosotros">Nosotros:</label>
        <textarea
          id="nosotros"
          value={nosotros}
          onChange={(e) => setnosotros(e.target.value)}
        />
        </td>
        </thead>
        <thead>
          <td>
        <label htmlFor="tu">Tu:</label>
        <textarea
          id="tu"
          value={tu}
          onChange={(e) => settu(e.target.value)}
        />
        </td>

        <td>
        <label htmlFor="vosotros">Vosotros</label>
        <textarea
          id="vosotros"
          value={vosotros}
          onChange={(e) => setvosotros(e.target.value)}
        />
        </td>
        </thead>

        <thead>
          <td>
        <label htmlFor="el">El/Ella/Usted:</label>
        <textarea
          id="el"
          value={el}
          onChange={(e) => setel(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="ellos">Ellos/Ellas/Ustedes:</label>
        <textarea
          id="ellos"
          value={ellos}
          onChange={(e) => setellos(e.target.value)}
        />
        </td>
        </thead>
        </table>
        <SpanishButtons />
        <button className="submit">Finish Edit</button>

        {formError && <p className="error">{formError}</p>}
      </form>
      </div>

      
        
    </div>
  )
}

export default Update