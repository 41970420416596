import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import { Analytics } from '@vercel/analytics/react'
;
// pages
import Home from "./pages/Home"
import About from "./pages/About"
import Create from "./pages/Create"
import Update from "./pages/Update"
import Study from "./pages/Study"
import Congrats from "./pages/Congrats"
import Fill from "./pages/Fill"
import Group from "./pages/Group"
import MC from "./pages/MC"
import Groups from "./pages/Sets"
import Bugs from "./pages/Bugs"
import FillIrregular from "./pages/FillIrregular"
import UpdateIrregular from "./pages/UpdateIrregular"


function App({ Component, pageProps }) {
  return (
    <BrowserRouter>
      <nav>
        <h1>ConjuQuick</h1>
        <Link to="/">Home</Link>
        <Link to="/groups">Groups</Link>
        <Link to="/bugs">Submit a bug</Link>

      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create" element={<Create />} />
        <Route path="/about" element={<About />} />
        <Route path="/bugs" element={<Bugs />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/:id/update" element={<Update />} />
        <Route path="/:id/fill/complete" element={<Congrats />} />
        <Route path="/:id/update/irregular" element={<UpdateIrregular />} />
        <Route path="/:id/study" element={<Study />} />
        <Route path="/:group/study" element={<Group />} />
        <Route path="/:id/fill" element={<Fill />} />
        <Route path="/:id/fill/irregular" element={<FillIrregular/>} />
      </Routes>
      <Analytics/>
      
    </BrowserRouter>

  );
}

export default App;
