import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import supabase from "../config/supabaseClient"
import {Helmet} from "react-helmet";

const UpdateIrregular = () => {
  const { id } = useParams()
  const chart = id;
  const navigate = useNavigate()
  const [iverb, setiverb] = useState('')
  const [iyo, setiyo] = useState('')
  const [itu, setitu] = useState('')
  const [iel, setiel] = useState('')
  const [inosotros, setinosotros] = useState('')
  const [ivosotros, setivosotros] = useState('')
  const [iellos, setiellos] = useState('')
  const [formError, setFormError] = useState(null)


  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!iverb) {
      setFormError('Please add a verb')
      return
    }

    const { data, error } = await supabase
      .from('irregulars')
      .update({ iyo, itu, iel, inosotros, ivosotros, iellos, iverb })
      .eq('id', id)
      .select()

    if (error) {
      setFormError('Please fill in all the fields correctly.')
    }
    if (data) {
      setFormError(null)
      navigate('/')
    }
  }



  useEffect(() => {
    const fetchChart = async () => {
      const { data, error } = await supabase
        .from('irregulars')
        .select()
        .eq('id', id)
        .single()

      if (error) {
        navigate('/', { replace: true })
      }
      if (data) {
        setiverb(data.iverb)
        setiyo(data.iyo)
        setitu(data.itu)
        setiel(data.iel)
        setinosotros(data.inosotros)
        setivosotros(data.ivosotros)
        setiellos(data.iellos)
        
      }
    }
      fetchChart()
  }, [id, navigate])

  return (
    <div className="form-grid">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Update Irregular</title>
                <link rel="canonical" href="http://conuquick.com" />
      </Helmet>

      {/* Normal Input*/}
      <div className="chart-input">
      <form onSubmit={handleSubmit}>
        <h2>Edit Irregular:</h2>
        <label htmlFor="title">Title:</label>
        
        <input
          type="text"
          id="title"
          value={iverb}
          onChange={(e) => setiverb(e.target.value)}
        />
        <table>
          <thead>
            <td>
        <label htmlFor="yo">Yo:</label>
        <textarea
          id="yo"
          value={iyo}
          onChange={(e) => setiyo(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="nosotros">Nosotros:</label>
        <textarea
          id="nosotros"
          value={inosotros}
          onChange={(e) => setinosotros(e.target.value)}
        />
        </td>
        </thead>
        <thead>
          <td>
        <label htmlFor="tu">Tu:</label>
        <textarea
          id="tu"
          value={itu}
          onChange={(e) => setitu(e.target.value)}
        />
        </td>

        <td>
        <label htmlFor="vosotros">Vosotros</label>
        <textarea
          id="vosotros"
          value={ivosotros}
          onChange={(e) => setivosotros(e.target.value)}
        />
        </td>
        </thead>

        <thead>
          <td>
        <label htmlFor="el">El/Ella/Usted:</label>
        <textarea
          id="el"
          value={iel}
          onChange={(e) => setiel(e.target.value)}
        />
        </td>
        <td>
        <label htmlFor="ellos">Ellos/Ellas/Ustedes:</label>
        <textarea
          id="ellos"
          value={iellos}
          onChange={(e) => setiellos(e.target.value)}
        />
        </td>
        </thead>
        </table>


        <button className="submit">Finish Edit</button>

        {formError && <p className="error">{formError}</p>}
      </form>
      </div>
    </div>
  )
}

export default UpdateIrregular