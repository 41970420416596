import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import {Helmet} from "react-helmet";
import {useNavigate } from 'react-router-dom'

//components
import ChartCard from "../components/chart"

const Verbs = () => {
  const navigate = useNavigate()
  const verbsarray = [];  
  const [fetchError, setFetchError] = useState(null)
  const [verbs, setVerbs] = useState(null)
  const [hasFetched, setHasFetched] = useState(false);
  const [orderBy, setOrderBy] = useState('created_at')


  async function findStudy(verb){
    const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('verb', verb)
        .order('id', { ascending: true})
        .limit(1)
        .single()

      if (error) {
        console.log(error);
      }
      if (data) {
        console.log(data.id)
        navigate('/' + data.id + '/study')
        
      }
  }



  

  useEffect(() => {
    const fetchCharts = async () => {
      if (!hasFetched) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .not("verb", "eq", null)
        .not("verb", "eq", "")



        //.order(orderBy, { ascending: false })

      if (error) {
        setFetchError('could not fetch the charts')
        setVerbs(null)
        console.log(error)
      }

      if (data) {
        setVerbs(data)    
        setFetchError(null)
        setHasFetched(true);
      }
    }
    }
    
    fetchCharts()
  }, //[orderBy]
  )
  return (
    <div className="page home">
      <Helmet>
                <meta charSet="utf-8" />
                <title>ConjuQuick</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
      {fetchError && (<p>{fetchError}</p>)}
      {verbs && (
      <div className="verbs">      
          {verbs && verbs.map(verb => {if (verbsarray.includes(verb.verb) === false){
            verbsarray.push(verb.verb)
            return (
              <button onClick={() => findStudy(verb.verb)} className="set-bar" ><h1>{verb.verb} </h1></button>
            )

          }})}
          
    </div>
      )}
  </div>
  )
}

export default Verbs