import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom"
import React from 'react'
import Confetti from 'react-confetti'
//components

const Congrats = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [length, setlength] = useState('')
  const [name, setName] = useState('')
  const [fetchError, setFetchError] = useState(null)
  const [charts, setCharts] = useState(null)
  const [irregulars, setIrregulars] = useState(null)
  const [verb, setVerb] = useState(null)
  const [isverb, setIsVerb] = useState(false)
  //TODO: add window heights for confetti
  
  function repeat() {
    navigate('/' + id + '/study')
  }
  
  function message() {
    if (isverb === true ){
      return( <h1>{'You completed all sets in ' + (verb)} </h1>)
    }
    else {
      return( <h1>{'You completed all ' + (length) + ' sets in ' + (name)} </h1>)
    }
  }
  useEffect(() => {

    
    const fetchCharts = async () => {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('id', id)
        .single()
        

      if (error) {
        setFetchError('could not fetch the charts')
        setCharts(null)
        console.log(error)
      }

      if (data) {
        setCharts(data)
        if (data.verb != null){
          setVerb(data.verb)
          setIsVerb(true)
        }
        setName(data.title)
        setFetchError(null)
      }
    }
    const fetchIrregs = async () => {
        const { data, error } = await supabase
          .from('irregulars')
          .select('*', { count: 'exact' })
          .eq('chart', id)
          
  
        if (error) {
          setFetchError('could not fetch the irregulars')
          setIrregulars(null)
          console.log(error)
        }
  
        if (data) { 
          setlength(data.length + 1)
          setFetchError(null)
        }
      }
    fetchIrregs()
    fetchCharts()
  })
  return (
    <div className="congrats">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Good Job!</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
        <Confetti
      />
        <h1>Good Job!</h1>
        {message()}
       
        <button onClick={repeat} className='button'>Study Again</button>
    </div>
    
  ) 
}
  
  export default Congrats