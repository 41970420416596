import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import {Helmet} from "react-helmet";

const About = () => {
  

  useEffect(() => {
  }
  )
  return (
    <div className="about-container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>About ConjuQuick</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
      <h1 className="about-title">About ConjuQuick</h1>
      <div className="about-section">
        <h2 className="about-subtitle">Our Mission</h2>
        <p className="about-text">
          Our mission at ConjuQuick is to make learning Spanish conjugations fun and interactive. We believe that everyone should have access to quality language education, and our platform is designed to make that a reality.
        </p>
        <div className="about-break"></div>
      </div>
      <div className="about-section">
        <h2 className="about-subtitle">Our Approach</h2>
        <p className="about-text">
          ConjuQuick uses the latest technology and modern design to provide a unique and engaging experience for Spanish learners of all levels. Whether you're a beginner just starting out, or a seasoned Spanish speaker looking to refresh your skills, ConjuQuick has everything you need to succeed.
        </p>
        <div className="about-break"></div>
      </div>
      <div className="about-section">
        <h2 className="about-subtitle">Join the Community</h2>
        <p className="about-text">
          So what are you waiting for? Join the ConjuQuick community today and start conjugating with confidence! Our supportive community is always here to help you reach your goals, so why not give it a try?
        </p>
      </div>
    </div>
  )
}

export default About