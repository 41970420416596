import { Link } from "react-router-dom"
import supabase from "../config/supabaseClient"
const irregular = ({ irregular, onDelete }) => {
    const handleDelete = async () => {
        const { data, error } = await supabase
            .from('irregulars')
            .delete()
            .eq('id', irregular.id)
            .select()
        if (error) {
            console.log(error)
        }
        if (data) {
            console.log(data)
            //onDelete(irregular.id)
        }
    }
    
    return (
        <div className="chart-card">
            <h3 className="title">{irregular.iverb}</h3>
            <table>
            <thead>
                <td>{irregular.iyo}</td>
                <td>{irregular.inosotros}</td>
            </thead>
            <thead>
                <td>{irregular.itu}</td>
                <td>{irregular.ivosotros}</td>
            </thead>
            <thead>
                <td>{irregular.iel}</td>
                <td>{irregular.iellos}</td>
            </thead>
            </table>
            
            <div className="buttons">
                <Link to={'/' + irregular.id + '/update'}>
                    <i className="material-icons">edit</i>
                </Link>
                <i className="material-icons" onClick={handleDelete}>delete</i>
            </div>
        </div>

    )
}

export default irregular