import { useState } from "react"
import { useNavigate } from "react-router-dom"
import supabase from "../config/supabaseClient"
import {Helmet} from "react-helmet";



const Create = () => {
  const navigate = useNavigate()

  const [bug, setBug] = useState('')
  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!bug) {
      setFormError('Please fill out the form')
      return
    }

    const { data, error } = await supabase
      .from('bugs')
      .insert([{bug}])
      .select()

    if (error) {
      setFormError('Please fill in all the fields correctly.')
      console.log(error)
    }
    if (data) {
      setFormError(null)
      navigate("/")
    }

  }
    

  return (
    <div className="form-grid">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Submit a bug</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
      {/* Normal Input*/}
      <div className="chart-input">
      <form onSubmit={handleSubmit}>
        <h2>Submit a Bug</h2>
        <label htmlFor="title">Please Describe the bug you encountered:</label>
        
        <textarea
          rows={8} 
          
          type="text"
          style={{}}
          id="title"
          value={bug}
          onChange={(e) => setBug(e.target.value)}
        />
        <button className="submit">Submit Bug</button>

        {formError && <p className="error">{formError}</p>}
      </form>
      </div>
      </div>

  )
}

export default Create