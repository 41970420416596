import supabase from "../config/supabaseClient"
import { useEffect, useState } from "react"
import {Helmet} from "react-helmet";

//components
import ChartCard from "../components/chart"

const Home = () => {
  const [fetchError, setFetchError] = useState(null)
  const [charts, setCharts] = useState(null)
  const [preteriteCharts, setPreteriteCharts] =useState (null)
  const [pperfcharts, setPPerfCharts] = useState(null)
  const [pastpcharts, setPastPCharts] = useState(null)
  const [pprogcharts, setPProgCharts] = useState(null)
  const [imperfectcharts, setImperfectCharts] = useState(null)
  const [conditionalcharts, setConditionalCharts] = useState(null)
  const [futurecharts, setFutureCharts] = useState(null)
  const [subjunctivecharts, setSubjunctiveCharts] = useState(null)
  const [isubjunctivecharts, setISubjunctiveCharts] = useState(null)
  const [hasFetchedPreterite, setHasFetchedPreterite] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasFetchedPastP, setHasFetchedPastP] = useState(false);
  const [HasFetchedPPerf, setHasFetchedPPerf] = useState(false);
  const [hasFetchedPProg, setHasFetchedPProg] = useState(false);
  const [hasFetchedImperfect, setHasFetchedImperfect] = useState(false);
  const [hasFetchedConditional, setHasFetchedConditional] = useState(false);
  const [hasFetchedFuture, setHasFetchedFuture] = useState(false);
  const [hasFetchedSubjunctive, setHasFetchedSubjunctive] = useState(false);
  const [hasFetchedISubjunctive, setHasFetchedISubjunctive] = useState(false);
  const [orderBy, setOrderBy] = useState('created_at')

  const handleDelete = (id) => {
    setCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
    })
    setPPerfCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
    })
    setPastPCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
    })
    setPProgCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
    })
    setPreteriteCharts(prevCharts => {
      return prevCharts.filter(sm => sm.id !== id)
    })
  }

  useEffect(() => {
    const fetchPPerfCharts = async () => {
      if (!HasFetchedPPerf) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('tense', 'Present Perfect')
        .select()
        //.order(orderBy, { ascending: false })

      if (error) {
        setFetchError('could not fetch the charts')
        setPPerfCharts(null)
        console.log(error)
      }

      if (data) {
        setPPerfCharts(data)
        setFetchError(null)
        setHasFetchedPPerf(true);
      }
    }
    }

    const fetchPastPCharts = async () => {
      if (!hasFetchedPastP) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('tense', 'Past Perfect')
        .select()
        //.order(orderBy, { ascending: false })

      if (error) {
        setFetchError('could not fetch the charts')
        setPastPCharts(null)
        console.log(error)
      }

      if (data) {
        setPastPCharts(data)
        setFetchError(null)
        setHasFetchedPastP(true);
      }
    }
    }


    const fetchCharts = async () => {
      if (!hasFetched) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('tense', 'Present Indicative')
        .select()
        //.order(orderBy, { ascending: false })

      if (error) {
        setFetchError('could not fetch the charts')
        setCharts(null)
        console.log(error)
      }

      if (data) {
        setCharts(data)
        setFetchError(null)
        setHasFetched(true);
      }
    }
    }

    const fetchPreterite = async () => {
      if (!hasFetchedPreterite) {
      const { data, error } = await supabase
        .from('charts')
        .select()
        .eq('tense', 'Preterite')
        .select()
        //.order(orderBy, { ascending: false })

      if (error) {
        setFetchError('could not fetch the charts')
        setPreteriteCharts(null)
        console.log(error)
      }

      if (data) {
        console.log("data")
        setPreteriteCharts(data)
        setFetchError(null)
        setHasFetchedPreterite(true);
      }
    }
  }

  const fetchPProg = async () => {
    if (!hasFetchedPProg) {
    const { data, error } = await supabase
      .from('charts')
      .select()
      .eq('tense', 'Present Progressive')
      .select()
      //.order(orderBy, { ascending: false })

    if (error) {
      setFetchError('could not fetch the charts')
      setPProgCharts(null)
      console.log(error)
    }

    if (data) {
      console.log("data")
      setPProgCharts(data)
      setFetchError(null)
      setHasFetchedPProg(true);
    }
  }
}

const fetchImperfect = async () => {
  if (!hasFetchedImperfect) {
  const { data, error } = await supabase
    .from('charts')
    .select()
    .eq('tense', 'Imperfect')
    .select()
    //.order(orderBy, { ascending: false })

  if (error) {
    setFetchError('could not fetch the charts')
    setImperfectCharts(null)
    console.log(error)
  }

  if (data) {
    console.log("data")
    setImperfectCharts(data)
    setFetchError(null)
    setHasFetchedImperfect(true);
  }
}
}

const fetchConditional = async () => {
  if (!hasFetchedConditional) {
  const { data, error } = await supabase
    .from('charts')
    .select()
    .eq('tense', 'Conditional')
    .select()
    //.order(orderBy, { ascending: false })

  if (error) {
    setFetchError('could not fetch the charts')
    setConditionalCharts(null)
    console.log(error)
  }

  if (data) {
    console.log("data")
    setConditionalCharts(data)
    setFetchError(null)
    setHasFetchedConditional(true);
  }
}
}


const fetchFuture = async () => {
  if (!hasFetchedFuture) {
  const { data, error } = await supabase
    .from('charts')
    .select()
    .eq('tense', 'Future')
    .select()
    //.order(orderBy, { ascending: false })

  if (error) {
    setFetchError('could not fetch the charts')
    setFutureCharts(null)
    console.log(error)
  }

  if (data) {
    console.log("data")
    setFutureCharts(data)
    setFetchError(null)
    setHasFetchedFuture(true);
  }
}
}

const fetchSubjunctive = async () => {
  if (!hasFetchedSubjunctive) {
  const { data, error } = await supabase
    .from('charts')
    .select()
    .eq('tense', 'Present Subjunctive')
    .select()
    //.order(orderBy, { ascending: false })

  if (error) {
    setFetchError('could not fetch the charts')
    setSubjunctiveCharts(null)
    console.log(error)
  }

  if (data) {
    console.log("data")
    setSubjunctiveCharts(data)
    setFetchError(null)
    setHasFetchedSubjunctive(true);
  }
}
}

const fetchISubjunctive = async () => {
  if (!hasFetchedISubjunctive) {
  const { data, error } = await supabase
    .from('charts')
    .select()
    .eq('tense', 'Imperfect Subjunctive')
    .select()
    //.order(orderBy, { ascending: false })

  if (error) {
    setFetchError('could not fetch the charts')
    setISubjunctiveCharts(null)
    console.log(error)
  }

  if (data) {
    console.log("data")
    setISubjunctiveCharts(data)
    setFetchError(null)
    setHasFetchedISubjunctive(true);
  }
}
}



    fetchCharts()
    fetchPPerfCharts()
    fetchPastPCharts()
    fetchPreterite()
    fetchPProg()
    fetchImperfect()
    fetchConditional()
    fetchFuture()
    fetchSubjunctive()
    fetchISubjunctive()
  }, //[orderBy]
  )
  return (
    <div className="page home">
      <Helmet>
                <meta charSet="utf-8" />
                <title>ConjuQuick</title>
                <link rel="canonical" href="http://conjuquick.com" />
      </Helmet>
      {fetchError && (<p>{fetchError}</p>)}
      {charts && (
      <div className="charts">
      <h1 className="header-bar">Present Indicative</h1>
          <div className="chart-grid">
            {charts && charts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Present Perfect</h1>
          <div className="chart-grid">
            {pperfcharts && pperfcharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Past Perfect</h1>
          <div className="chart-grid">
            {pastpcharts && pastpcharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Present Progressive</h1>
          <div className="chart-grid">
            {pprogcharts && pprogcharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Preterite</h1>
          <div className="chart-grid">
            {preteriteCharts && preteriteCharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Imperfect</h1>
          <div className="chart-grid">
            {imperfectcharts && imperfectcharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Conditional</h1>
          <div className="chart-grid">
            {conditionalcharts && conditionalcharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Future</h1>
          <div className="chart-grid">
            {futurecharts && futurecharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Present Subjunctive</h1>
          <div className="chart-grid">
            {subjunctivecharts && subjunctivecharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
      <h1 className="header-bar">Imperfect Subjunctive</h1>
          <div className="chart-grid">
            {isubjunctivecharts && isubjunctivecharts.map(chart => (
              <ChartCard key={chart.id} chart={chart} onDelete={handleDelete}/>
            ))}
          </div>
          
    </div>
      )}
    </div>
  )
}

export default Home