import React, { useState } from 'react';
import { useRef } from 'react';

const SpanishCharacterButtons = () => {
const [selectedLetter, setSelectedLetter] = useState("");

const spanishLetters = [
  "á",
  "é",
  "í",
  "ó",
  "ú",
  "ñ",
];

const handleLetterClick = letter => {
  setSelectedLetter(letter);
  document.execCommand("insertText", false, letter);
};

return (
  <div className="spanish-letter-bar">
    {spanishLetters.map(letter => (
      <button
        key={letter}
        type="button" 
        className="letter-button"
        onClick={() => handleLetterClick(letter)}
      >
        {letter}
      </button>
    ))}
  </div>
);
};

export default SpanishCharacterButtons;
